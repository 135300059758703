/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

require('../css/app.scss');

import jquery from 'jquery';

global.$ = jquery;
global.jQuery = jquery;

import 'popper.js';
import 'bootstrap';

/*import routes from '../../public/js/fos_js_routes.json';
import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);
global.Routing = Routing;*/

console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
